body {
  background-color: rgb(47, 53, 57);
}

.navbar-default .navbar-nav > li > a {
  line-height: 45px;
  text-transform: uppercase;
  font-weight: 700;
}

.navbar-default .navbar-nav > li > a.purchase {
  margin-top: 7px;
}

.navbar-default {
  background-color: white;
}

body > iframe {
  pointer-events: none;
}

.brand {
  font-size: 180%;
  font-weight: 600;
}
