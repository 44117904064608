/*
Template Name: BeSmart - Startup Landing Page Template
Author URI: http://themeforest.net/user/bogdan_09
Author Name: Bogdan
Version: 1.0
*/

/* --- TABLE OF CONTENT

01. GENERAL LAYOUT
02. FOOTER
03. HOME
04. SERVICES
05. NEWSLETTER
06. TEAM
07. BLOG
08. REGISTER
09. PRICING
10. CONTACT
11. OTHER STYLES
		1. Buttons
		2. Partners
		3. Navigation
		4. Fun Facts
		5. Testimonials
		6. Background Image
		7. Page Loader
12. MOBILE DEVICES

--- */

/* ========================================================== */
/* 			            01. GENERAL LAYOUT                    */
/* ========================================================== */

.no-padding {
  padding: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-left: 0;
}

.small-padding-left {
  padding-left: 5px;
}

.small-padding-right {
  padding-right: 5px;
}

ul,
ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
  color: #858585;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
}

ul li,
ol li {
  font-size: 14px;
  line-height: 28px;
}

ul li i {
  vertical-align: middle;
}

ul.features-list-dark {
  display: inline-block;
}

ul.features-list-dark li {
  float: left;
  font-size: 15px;
  line-height: 28px;
  margin: 1px 0;
}

ul.features-list li {
  float: left;
  font-size: 15px;
  line-height: 24px;
  margin: 12px 0;
}
ul.features-list li img {
  max-width: 55px;
  margin-right: 20px;
  float: left;
}

p {
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: #858585;
  margin-bottom: 15px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #454545;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

h2.section-title {
  font-size: 36px;
  margin: 0 0 15px 0;
}

h2.section-title.grey {
  color: #ddd;
}

h2.large-title {
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 500;
}

p.section-subtitle {
  font-size: 17px;
}

p.section-subtitle.grey {
  color: #bbb;
}

.separator_wrapper,
.separator_wrapper_white {
  margin-bottom: 10px;
}

.separator_wrapper::after,
.separator_wrapper::before {
  background-color: #34b1c4;
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 60px;
  height: 1px;
  margin-top: -6px;
}

.separator_wrapper_white::after,
.separator_wrapper_white::before {
  background-color: #ddd;
  display: inline-block;
  vertical-align: middle;
  content: '';
  width: 60px;
  height: 1px;
  margin-top: -6px;
}

h3.medium-title {
  font-size: 32px;
  margin-top: 10px;
}

iframe {
  max-width: 100%;
  border: none;
}

.width-100 {
  width: 100%;
}

.medium {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.lyla {
  color: #9e5fba;
}

.green {
  color: #16a085;
}

.blue {
  color: #3498db;
}

.red {
  color: #34b1c4;
}
.grey {
  color: #ddd;
}

.white {
  color: #fff;
}

.section-white {
  background-color: #fff;
  padding: 90px 0;
}

.section-grey {
  background-color: #eef0f1;
  padding: 90px 0;
}

.section-grey.small-padding,
.section-white.small-padding {
  padding: 40px 0;
}

.section-grey.medium-padding,
.section-white.medium-padding,
.section-dark.medium-padding {
  padding: 60px 0;
}

.section-grey.no-padding-bottom,
.section-white.no-padding-bottom,
.section-dark.no-padding-bottom,
.section-blue.no-padding-bottom {
  padding: 90px 0 0 0;
}

.section-grey.small-padding-bottom,
.section-white.small-padding-bottom,
.section-dark.small-padding-bottom,
.section-blue.small-padding-bottom {
  padding: 90px 0 60px 0;
}

.section-grey.no-padding,
.section-white.no-padding,
.section-dark.no-padding,
.section-blue.no-padding {
  padding: 0;
}

.section-white.about-padding {
  padding: 85px 0 100px 0;
}

.section-dark.portfolio-padding {
  padding: 60px 0 75px 0;
}

.section-dark {
  background-color: #393f43;
  padding: 90px 0;
}

.section-blue {
  background-color: #34b1c4;
  padding: 90px 0;
}

/* margin and padding classes */

.margin-right-25 {
  margin-right: 25px !important;
}

.margin-top-110 {
  margin-top: 110px !important;
}

.margin-top-100 {
  margin-top: 100px !important;
}

.margin-top-90 {
  margin-top: 90px !important;
}

.margin-top-80 {
  margin-top: 80px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-35 {
  margin-top: 35px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-bottom-70 {
  margin-bottom: 70px !important;
}

.margin-bottom-80 {
  margin-bottom: 80px !important;
}

.margin-bottom-100 {
  margin-bottom: 100px !important;
}

.margin-bottom-120 {
  margin-bottom: 120px !important;
}

.margin-bottom-130 {
  margin-bottom: 120px !important;
}

.margin-bottom-140 {
  margin-bottom: 140px !important;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-top-40 {
  padding-top: 40px !important;
}

.padding-top-45 {
  padding-top: 45px !important;
}

.padding-top-50 {
  padding-top: 50px !important;
}
.padding-top-60 {
  padding-top: 60px !important;
}

.padding-top-70 {
  padding-top: 70px !important;
}

.padding-top-80 {
  padding-top: 80px !important;
}

.padding-top-100 {
  padding-top: 180px !important;
}

.padding-bottom-60 {
  padding-bottom: 60px !important;
}

.padding-bottom-70 {
  padding-bottom: 70px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

/* carousel */
.carousel.carousel1 .carousel-indicators {
  position: inherit;
  right: 0px;
}

.carousel.carousel1 .item .lead {
  font-size: 20px;
}

.carousel.carousel1 .carousel-indicators li {
  background-color: #e0e0e0;
  width: 16px;
  height: 16px;
}

.carousel.carousel1 .carousel-indicators li.active {
  background-color: #34b1c4;
}

.carousel.carousel3 .carousel-indicators {
  position: inherit;
  right: 0px;
}

.carousel.carousel3 .item .lead {
  font-size: 20px;
}

.carousel.carousel3 .carousel-indicators li {
  background-color: #f9f9f9;
  width: 16px;
  height: 16px;
}

.carousel.carousel3 .carousel-indicators li.active {
  background-color: #34b1c4;
}

.carousel.carousel-fade .item {
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.carousel.carousel-fade .active.item {
  opacity: 1;
}

.carousel.carousel-fade .active.left,
.carousel.carousel-fade .active.right {
  left: 0;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=0);
}

.carousel.carousel-fade .next,
.carousel.carousel-fade .prev {
  left: 0;
  z-index: 1;
}

.carousel.carousel-fade .carousel-control {
  z-index: 3;
}

.carousel-indicators {
  bottom: auto;
  left: auto;
  margin: 0;
  right: 16px;
  top: 16px;
  width: auto;
  line-height: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.carousel-indicators li {
  vertical-align: top;
  border: none;
  width: 8px;
  height: 8px;
  margin: 0 0 0 6px;
  background-color: #2c3e50;
  background-color: rgba(44, 62, 80, 0.5);
}
.carousel-indicators li.active {
  background-color: #fff;
  width: 8px;
  height: 8px;
  margin: 0 0 0 6px;
}
/* end carousel */

/* -----  Photos Hover Animations ----- */
.popup-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
}

.popup-gallery {
  display: inline-block;
  position: relative;
}

.first-gallery {
  margin-bottom: 15px;
}

.popup-gallery img {
  position: relative;
  z-index: 10;
  width: 100%;
  height: auto;
}

.popup-gallery:hover img {
  opacity: 0.25;
}

.popup-gallery a span.eye-wrapper,
.popup-gallery a span.eye-wrapper2 {
  background-color: #000;
  position: absolute;
  display: block;
  overflow: hidden;
  z-index: 2;
  height: 100% !important;
  top: 0%;
  margin-top: 0px;
  left: 0%;
  right: 0%;
  font-size: 22px;
  color: #fff;
  text-align: center;
  font-weight: 300;
  opacity: 0;
}

.popup-gallery a span.eye-wrapper i.eye-icon,
.popup-gallery a span.eye-wrapper2 i.eye-icon {
  position: absolute;
  display: inline-block;
  font-size: 38px;
  z-index: 3;
  top: 50% !important;
  margin-top: -19px !important;
  left: 0%;
  right: 0%;
}

.popup-gallery a:hover span {
  opacity: 1;
}

.popup-gallery img,
.popup-gallery a span {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

/* ========================================================== */
/* 			                02. FOOTER                        */
/* ========================================================== */

.footer {
  z-index: 900;
  background-color: #2f3539;
  border-top: 1px solid #393f43;
  padding: 55px 0;
  font-weight: 300;
}

.footer p {
  font-size: 16px;
  color: #cecece;
}

.footer p a {
  font-size: 16px;
  color: #34b1c4;
  text-decoration: underline;
}

/* ----- 2. Footer Social Icons ----- */
ul.footer_social {
  width: 100%;
  max-width: 1140px;
  display: block;
  margin: 0 auto;
  padding-top: 5px;
  text-align: center;
}

ul.footer_social li {
  display: inline-block;
  margin: 0;
  line-height: 100% !important;
}

ul.footer_social li a i {
  display: inline-block;
  margin: 0 8px;
  width: 36px;
  height: 36px;
  padding: 8px 8px;
  border: 1px solid #8d8d8d;
  border-radius: 50%;
  font-size: 18px !important;
  color: #8d8d8d;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

ul.footer_social li a i:hover {
  color: #fff;
  background-color: #34b1c4;
  border: 1px solid #34b1c4;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

p.footer-logo {
  margin-bottom: 21px;
  font-size: 34px;
  font-weight: 700;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

/* ========================================================== */
/* 			                 03. HOME                         */
/* ========================================================== */

.home-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 100;
  padding: 165px 0 85px 0;
  background: url(../images/bg.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

.home-wrapper .gradient_overlay {
  opacity: 0.78;
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #151515;
}

.home-wrappe-inside {
  position: relative;
  z-index: 100;
}

h1.home-title {
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

p.home-subtitle {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}

.frame-border {
  margin-top: 30px;
  border: 9px solid rgba(0, 0, 0, 0.4);
  webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

/* ========================================================== */
/* 				  		   04. SERVICES                       */
/* ========================================================== */

.features-item {
  margin: 10px 0;
  text-align: center;
}

.features-item h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.features-item p {
  padding: 0 10px;
}

.icon-wrapper {
  margin-bottom: 25px;
  width: 100%;
}

.features-icon {
  padding: 18px 22px;
  font-size: 42px;
  line-height: 42px;
  display: inline-block;
  text-align: center;
  color: #fff;
  background-color: #34b1c4;
  -webkit-border-radius: 8px 8px;
  -moz-border-radius: 8px 8px;
  border-radius: 8px 8px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.services-item {
  margin: 20px 0;
}

.services-icon {
  display: block;
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0px;
  top: 5px;
  color: #34b1c4;
  font-size: 48px;
  line-height: 70px;
  text-align: center;
}

.services-text {
  padding-left: 60px;
}

.services-text h4 {
  font-size: 18px;
  margin-bottom: 8px;
  color: #454545;
}

/* ========================================================== */
/* 						   05. NEWSLETTER                     */
/* ========================================================== */
.newsletter-wrapper {
  width: 100%;
  position: relative;
  background: url(http://placehold.it/1920x1280);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin: 0 auto;
  padding: 110px 0 110px 0;
}

.newsletter-wrapper .image-overlay {
  background: rgba(34, 34, 34, 0.65);
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.newsletter-box {
  position: relative;
  z-index: 100;
}

.newsletter_info h2 {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 30px;
}

#newsletter-form #email_newsletter {
  width: 91%;
  max-width: 360px;
  height: 56px;
  line-height: 56px;
  padding: 0 4%;
  border: 2px solid #fff;
  background-color: transparent;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
  color: #fff;
  font-size: 16px;
  margin: 0 10px 0 0;
}

#newsletter-form #submit-button-newsletter {
  width: 140px;
  height: 56px;
  background-color: #fff;
  border: 2px solid #fff;
  color: #454545;
  display: inline;
  margin: 0 0 0 -15px;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
  font-size: 14px;
  font-weight: 700;
  vertical-align: top;
  transition: background-color 0.25s ease-in-out;
  -moz-transition: background-color 0.25s ease-in-out;
  -webkit-transition: background-color 0.25s ease-in-out;
}

#newsletter-form #submit-button-newsletter:hover {
  background-color: #228798;
  border: 1px solid #228798;
  transition: background-color 0.25s ease-in-out;
  -moz-transition: background-color 0.25s ease-in-out;
  -webkit-transition: background-color 0.25s ease-in-out;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

p.newsletter_success_box {
  font-size: 18px;
  color: #eee;
  font-style: italic;
  margin-bottom: 15px;
}

/* ========================================================== */
/* 				  		     06. TEAM                         */
/* ========================================================== */

.team-box {
  text-align: center;
  background-color: #eef0f1;
  border-bottom: 3px solid #e0e0e0;
  margin-bottom: 20px;
  padding: 1px 20px 15px 20px;
  -webkit-border-radius: 0 0 5px 5px;
  -moz-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
}

.team-box h3 {
  margin: 20px 0 1px 0 !important;
  color: #454545;
  font-size: 18px;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

.team-item:hover h3 {
  color: #228798;
}

.team-box p.team-info {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 5px;
}

.team-popup {
  display: block;
  position: relative;
  overflow: hidden;
}

.team-popup .team-popup-overlay {
  background-color: #000;
  position: absolute;
  display: block;
  overflow: hidden;
  z-index: 2;
  height: 100% !important;
  top: 0%;
  margin-top: 0px;
  left: 0%;
  right: 0%;
  font-size: 22px;
  color: #fff;
  text-align: center;
  font-weight: 300;
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.team-popup .team-popup-overlay .team-icon {
  position: absolute;
  display: inline-block;
  z-index: 3000;
  top: 50% !important;
  margin-top: -15px !important;
  left: 0%;
  right: 0%;
}

.team-popup .team-popup-overlay .team-icon a {
  margin: 0 3px;
  color: #fff;
  font-size: 28px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.team-popup .team-popup-overlay .team-icon a:hover {
  color: #34b1c4;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.team-popup .team-popup-overlay:hover {
  opacity: 0.8 !important;
}

/* ========================================================== */
/* 				  		     07. BLOG                         */
/* ========================================================== */

.blog-item {
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.blog-item img {
}

.blog-item-inner {
  padding: 10px 0 20px 0;
}

.blog-item-inner p {
  margin: 15px 0 20px 0;
}

h3.blog-title {
  margin-top: 15px;
  margin-bottom: 10px;
}

h3.blog-title a {
  font-size: 21px;
  color: #454545;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

h3.blog-title a:hover {
  color: #228798;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

a.blog-icons {
  color: #858585;
  font-size: 14px;
  font-style: italic;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #aaa;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

a.blog-icons i {
  color: #656565;
  font-size: 16px;
  line-height: 16px;
  vertical-align: text-bottom;
}

a.blog-icons.last {
  border-right: none;
}

a.blog-icons:hover {
  color: #228798;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

a.button-blog {
  background-color: #34b1c4;
  border: 1px solid #34b1c4;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  font-size: 13px;
  padding: 10px 20px;
  margin: 0 0 20px 0;
  -webkit-border-radius: 3px 3px;
  -moz-border-radius: 3px 3px;
  border-radius: 3px 3px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

a.button-blog:hover {
  background-color: transparent;
  border: 1px solid #34b1c4;
  color: #34b1c4;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

/* ========================================================== */
/* 					       08. REGISTER                       */
/* ========================================================== */

.register-inner {
  width: 100%;
  max-width: 585px;
  padding: 50px 0 50px 35px;
}

#register-form {
  padding: 25px 0 20px 0;
}

input.register-input {
  width: 100%;
  height: 52px;
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 20px;
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
  font-size: 14px;
  float: left;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

select.register-input {
  width: 100%;
  height: 52px;
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 20px;
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
  font-size: 14px;
  float: left;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input.register-submit {
  color: #34b1c4;
  border: 1px solid #fff;
  background-color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  max-width: 535px;
  padding: 16px 0;
  text-transform: uppercase;
  -webkit-border-radius: 3px 3px;
  -moz-border-radius: 3px 3px;
  border-radius: 3px 3px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

input.register-submit:hover {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

input.white-input::-webkit-input-placeholder,
select.white-input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff !important;
}
input.white-input:-moz-placeholder,
select.white-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff !important;
}
input.white-input::-moz-placeholder,
select.white-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff !important;
}
input.white-input:-ms-input-placeholder,
select.white-input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

#register-form input.register-input:focus,
#register-form select.register-input:focus {
  border-color: #fff !important;
  outline: none;
}

p.register_success_box {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-style: italic;
  margin: 20px 0 30px 0;
  padding: 20px 20px;
  border: 1px solid #fff;
  webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

/* ========================================================== */
/* 				            09. PRICING                       */
/* ========================================================== */

.pricing-box {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 40px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  background-color: #f9f9f9;
  text-align: center;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
}

.pricing-box .pricing-top {
  padding: 20px 0;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

.pricing-box .pricing-top {
  background-color: #34b1c4;
}

.pricing-top h3 {
  color: #fff;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 700;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.pricing-top .price .currency {
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  vertical-align: top;
  display: inline-block;
}

.pricing-top .price span.number {
  color: #fff;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
}

.pricing-top .price .month {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}

.pricing-top .price .month .blue {
  color: #3498db;
}

.pricing-bottom {
  padding: 0 35px 40px 35px;
}

.pricing-bottom ul {
  list-style: none;
  list-style-type: none;
  margin: 35px 0px 40px;
  padding: 0;
}

.pricing-bottom ul li {
  text-align: center;
  font-size: 16px;
  line-height: 30px;
}

.btn-pricing-blue {
  color: #34b1c4;
  background-color: transparent;
  border: 2px solid #34b1c4;
  padding: 14px 0;
  font-weight: 600;
}

.btn-pricing-blue:hover {
  color: #fff;
  background-color: #34b1c4;
  border: 2px solid #34b1c4;
}

.btn-pricing-featured {
  color: #fff;
  background-color: #34b1c4;
  border: 2px solid #34b1c4;
  padding: 14px 0;
  font-weight: 600;
}

.btn-pricing-featured:hover {
  color: #34b1c4;
  background-color: transparent;
  border: 2px solid #34b1c4;
}

/* ========================================================== */
/* 						    10. CONTACT                       */
/* ========================================================== */

#contact-form {
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  text-align: center;
  margin-bottom: 10px;
}

input.contact-input {
  width: 100%;
  height: 55px;
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 20px;
  color: #fff;
  border: 1px solid #757575;
  background-color: transparent;
  font-size: 14px;
  float: left;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

textarea {
  height: 205px;
  margin-bottom: 20px;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  border: 1px solid #757575;
  background-color: transparent;
  font-size: 14px;
  float: left;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input.contact-submit {
  color: #fff;
  border: 2px solid #34b1c4;
  background-color: #34b1c4;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
  padding: 16px 30px;
  position: relative;
  text-transform: uppercase;
  -webkit-border-radius: 3px 3px;
  -moz-border-radius: 3px 3px;
  border-radius: 3px 3px;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

input.contact-submit:hover {
  color: #34b1c4;
  background-color: transparent;
  border: 2px solid #34b1c4;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

input.white-input::-webkit-input-placeholder,
textarea.white-input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}
input.white-input:-moz-placeholder,
textarea.white-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}
input.white-input::-moz-placeholder,
textarea.white-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}
input.white-input:-ms-input-placeholder,
textarea.white-input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

#contact-form input.contact-input:focus,
#contact-form textarea.contact-commnent:focus {
  border-color: #fff;
  outline: none;
}

p.contact_success_box {
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-style: italic;
  padding: 20px 20px;
  border: 1px solid #fff;
  webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

/* ========================================================== */
/* 		 		  	   	 11. OTHER STYLES                     */
/* ========================================================== */

.section_features {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 20px 0 0 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.no-padding {
  padding: 0;
}

.wrapper-inner {
  display: inline-block;
  width: 100%;
  max-width: 585px;
  padding: 40px 0 40px 35px;
}

.portfolio-pic {
  margin-bottom: 30px;
}

/* ----- 1. Buttons ----- */
.btn,
btn:hover {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.btn-margin {
  display: inline-block;
  margin: 10px 10px;
}

.btn-white {
  color: #454545;
  background-color: #fff;
  border-color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.btn-white-big {
  color: #8b62ba;
  background-color: #fff;
  border-color: #fff;
  padding: 20px 40px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
}

.btn-white-big:hover,
.btn-white-big:focus,
.btn-white-big:active,
.btn-white-big.active,
.open .dropdown-toggle.btn-white-big {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.btn-white-transparent {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
}

.btn-white-transparent:hover,
.btn-white-transparent:focus,
.btn-white-transparent:active,
.btn-white-transparent.active,
.open .dropdown-toggle.btn-white-transparent {
  color: #454545;
  background-color: #fff;
  border-color: #fff;
}

.btn-small-blue {
  color: #fff;
  font-weight: 600;
  background-color: #34b1c4;
  border: 2px solid #34b1c4;
  padding: 12px 25px;
}

.btn-small-blue:hover {
  color: #34b1c4;
  background-color: transparent;
  border-color: #34b1c4;
}

.btn-blue {
  background-color: #34b1c4;
  position: relative;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
}

.btn-blue.small {
  padding: 16px 28px;
}

.btn-blue.xsmall {
  padding: 12px 25px;
  text-transform: none;
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active,
.open .dropdown-toggle.btn-blue {
  color: #fff;
  background-color: #2980b9;
}

/* ----- 2. Partners ----- */
.partners-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
}

.partners-item {
  padding: 50px 0;
  text-align: center;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.partners-item.no-border-bottom {
  border-bottom: none;
}

.partners-item.last {
  border-right: none;
}

.partners-item img {
  opacity: 0.4;
  margin: 10px 25px;
  max-height: 36px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.partners-item img:hover {
  opacity: 1;
}

.section-grey.sponsors-padding {
  padding: 50px 0;
}

.section-grey.sponsors-padding-small {
  padding: 45px 0;
}

.sponsors img.sponsor {
  opacity: 0.4;
  margin: 10px 25px;
  max-height: 30px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.sponsors img.sponsor:hover {
  opacity: 0.8;
}

/* ----- 03. Nav ----- */
#navbar-collapse-02 li.selected a {
  color: #34b1c4 !important;
}

a.active {
  color: #34b1c4 !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > a:hover {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.navbar-default .navbar-nav > li > a.purchase {
  position: relative;
  color: #fff;
  background-color: #34b1c4;
  border: 1px solid #34b1c4;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 14px;
  padding: 0 25px;
  font-weight: 600 !important;
  letter-spacing: 1px;
  text-transform: none;
  webkit-border-radius: 5px 5px;
  -moz-border-radius: 5px 5px;
  border-radius: 5px 5px;
}

.navbar-default .navbar-nav > li > a.purchase:hover {
  color: #34b1c4 !important;
  background-color: transparent;
  border: 1px solid #34b1c4;
}

#navbar-collapse-02 .current a.purchase {
  color: #fff !important;
  background-color: #34b1c4 !important;
  border: 1px solid #34b1c4 !important;
}

/* ----- 4. Fun Facts ----- */
.fun-facts-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  background: url(http://placehold.it/1920x1280);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin: 0 auto;
  padding: 100px 0 100px 0;
}

.fun-facts-wrapper .image-overlay {
  background: rgba(34, 34, 34, 0.65);
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.fun-facts-inside {
  position: relative;
  z-index: 100;
}

.fun-facts-box {
  float: left;
  width: 19.99%;
  padding-top: 15px;
}

.fun-facts-title {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  font-style: italic;
}

.fun-facts-title span {
  font-size: 40px;
  line-height: 42px;
  font-weight: 600;
  font-style: normal;
  color: #fff;
}

.fun-facts-box i {
  font-size: 44px;
  line-height: 60px;
  color: #34b1c4;
}

/* ----- 5. Testimonials ----- */
.section-testimonials {
  position: relative;
  background-color: #34b1c4;
  padding: 60px 0;
}

.testimonials-wrapper {
  position: relative;
  max-width: 960px;
}

.testimonials-info {
  display: inline-block;
  padding: 10px 40px 10px 0;
  border-right: 1px solid #fff;
}

.testimonials-info img {
  float: left;
  margin-right: 20px;
  width: 110px;
  height: 110px;
  padding: 5px 5px;
  background-color: transparent;
  border: 1px solid #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

p.author-name {
  float: left;
  margin: 0;
  padding-top: 30px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
}

p.author-name span {
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  text-transform: none;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
}

p.testimonials-text {
  padding: 20px 0 0 0;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  color: #fff;
}

/* ----- 6. Background Image ----- */
.image-section {
  width: 100%;
  position: relative;
  z-index: 100;
  background: url(../images/download.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding: 0;
}

.image-section .image-overlay {
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.image-section-inside {
  position: relative;
  z-index: 100;
}

/* ----- 7. Page Loader ----- */
#loader {
  background: #34b1c4;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 90000;
  left: 0%;
  top: 0%;
}

.sk-three-bounce {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 50%;
  margin-left: -40px;
  width: 80px;
  text-align: center;
}

.sk-three-bounce .sk-child {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* ========================================================== */
/* 					    12. MOBILE DEVICES                    */
/* ========================================================== */

@media handheld, only screen and (max-width: 800px) {
  .testimonials-info {
    border-right: none;
    padding: 0;
  }

  .testimonials-wrapper {
    text-align: center;
  }

  img.author-pic {
    float: none;
    margin: 0;
  }

  p.author-name {
    float: none;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  p.testimonials-text {
    float: none;
    padding: 10px 0 0 0;
    font-size: 18px;
    line-height: 28px;
  }

  .fun-facts-box {
    width: 50%;
  }

  .team-item {
    margin-bottom: 30px;
  }

  .wrapper-inner {
    max-width: none;
    padding: 40px 0 80px 35px;
  }

  .partners-item {
    padding: 10px 0;
    border-right: none;
    border-bottom: none;
  }

  .col-md-4.small-padding-right,
  .col-md-6.small-padding-right {
    padding-right: 15px !important;
  }
}
